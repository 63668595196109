<template>
  <v-card
    flat
    class="bg-card-panel elevati"
    :loading="loadTask"    
  >
    <template slot="progress">
      <v-progress-linear
        color="icono"
        height="3"
        indeterminate
      />
    </template>
    <v-card-title class="d-flex justify-space-between align-center">
      <h3 class="blue-grey--text text--darken-3 font-weight-medium text-capitalize">
        <v-icon size="20">mdi-bookmark</v-icon>
        Tarea
      </h3>
      <slot name="switch">
        <v-switch
          v-model="loadTask"
          inset
          dense
          hide-details
          class="mt-0 mr-n2"
          :disabled="loadTask"
          @change="runTaskPayment()"
        />
      </slot>
    </v-card-title>
    <v-card-text class="d-flex align-center">
      <v-avatar
        color="info"              
        size="50" 
        class="rounded-xl"
      >
        <v-icon
          size="32"
          color="grey lighten-4"
          v-text="'mdi-cash-register'"
        />
      </v-avatar>
      <div class="d-flex flex-column ml-3">
        <h2
          class="primary--text font-weight-bold text-capitalize"
          style="line-height: 1.2em"
          v-text="'Iniciar Pago'"
        />
        <validation-observer ref="SELECT_ENTORNO" tag="div">
          <validation-provider
            v-slot="{ errors }"
            name="entorno"
            rules="required"
            vid="entorno"
          >
            <v-radio-group
              v-model="entorno"
              hide-details
              class="mt-0"
              :error="Boolean(errors[0])"
              row
            >
              <v-radio
                color="icono"
                label="Web"
                value="web"            
              >
                <template #label>
                  <span class="blue-grey--text text--darken-3 font-weight-regular">Web</span>
                </template>
              </v-radio>
              <v-radio
              color="icono"
                value="movil"
              >
                <template #label>
                  <span class="blue-grey--text text--darken-3 font-weight-regular">Móvil</span>
                </template>
              </v-radio>
            </v-radio-group>
          </validation-provider>
        </validation-observer>
      </div>
    </v-card-text>    
  </v-card>
</template>
<script>
import { taskStartPayment } from '@/services/tasks.js'


export default {
  name: 'CardTask',
  props: {
    details: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    loadTask: false,
    entorno: '',
  }),
  methods: {
    async runTaskPayment () {
      const VALIDATE_ENTORNO = await this.$refs.SELECT_ENTORNO.validate()
      if(this.loadTask && VALIDATE_ENTORNO) {
        try {
          const { message, pagos_creados, pagos_no_creados } = await taskStartPayment({ entorno: this.entorno })

          const MESSAGE_RESPONSE = `${message}. <br> 
            <strong>Pagos Creados:</strong> ${pagos_creados} <br> 
            <strong>Pagos No Creados:</strong> ${pagos_no_creados}`

          this.$root.$showAlert(
             MESSAGE_RESPONSE, 
            'success',
          )       
          this.$emit('update') 
        } catch (error) {
          this.$root.$showAlert(
            'Lo sentimos, hubo un error al intentar realizar esta acción en el Servidor.',
            'error',
          )
        } finally {
          this.loadTask = false
        }
      } else {
        this.loadTask = false
        this.$root.$showAlert(
          'Debes seleccionar un Entorno para ejecutar la Tarea.',
          'error',
        )
      }
    },
  },
}
</script>
<style>
.bg-card-panel {
  background: rgb(255,255,255);
  background: linear-gradient(0deg, rgba(240,244,249,1) 17%, rgba(211,224,238,1) 100%);
  border: 1px solid rgba(0,0,0,0.04) !important;
}
</style>